import React from "react";
import CourseCard from "../common/course/CourseCard";
import { Grid, Typography } from "@material-ui/core";
import Button from "@studytools/core/ui/components/Button";
import { Wrapper, Container } from "../global";
import { Course } from "@studytools/core/generated/graphql";
import { useInView } from "react-intersection-observer";

type Props = {
  courseList: Course[];
};

const Resources: React.FC<Props> = ({ courseList }) => {
  const handleClick = event => {
    event.preventDefault();
    window.location.href = "/explore";
  };
  const { ref, inView } = useInView({
    threshold: 0,
  });

  return (
    <Wrapper id="resources" ref={ref} style={{ height: inView ? "auto" : "1300px" }}>
      <Container>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <Typography variant="h3" align="center">
              <b>Recommended Courses</b>
            </Typography>
            <Grid container item spacing={3} justify="center" style={{ marginTop: "16px" }}>
              {inView &&
                courseList !== undefined &&
                Object.values(courseList).map((course, index) => {
                  if (course != null) {
                    return (
                      <Grid item key={index}>
                        <CourseCard course={course} />
                      </Grid>
                    );
                  } else {
                    return;
                  }
                })}
            </Grid>
          </Grid>
          <Grid item style={{ width: 300, marginTop: "32px" }}>
            <Button onClick={handleClick} fill="blue">
              <Typography variant="h6">DISCOVER MORE</Typography>
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default Resources;
