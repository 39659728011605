import React from "react";

import { WrapperColored, ContainerColored } from "../global";
import { Typography } from "@material-ui/core";
import Video from "../common/layout/video";

const Features: React.FC = () => {
  return (
    <WrapperColored>
      <ContainerColored>
        <Typography variant="h3" align="center">
          <b>Ako Maps In The Classroom</b>
        </Typography>
        <div
          style={{ width: "100%", maxWidth: "1080px", minWidth: "265px", margin: "auto", padding: "32px 16px 16px" }}
        >
          <Video title="Auckland Grammar Interview" src="https://player.vimeo.com/video/578297180" />
        </div>
      </ContainerColored>
    </WrapperColored>
  );
};

export default Features;
