import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import Button from "@studytools/core/ui/components/Button";
import { signUp } from "../common/links/signup";
import headerVideo from "../../videos/HeaderVideo.mp4";

const About: React.FC = () => {
  const handleSignup = event => {
    event.preventDefault();
    signUp();
  };
  return (
    <div id="header">
      <Container>
        <BackgroundVideo autoPlay loop muted playsInline id="header-video" src={headerVideo} />
        <Copy>
          <Typography variant="h2">
            <b>
              Your brain learns by making <Accent>connections</Accent>
            </b>
          </Typography>
          <Typography variant="h5">
            Ako Maps is a suite of learning tools that links visual diagrams to video lessons.
          </Typography>
          <Flex>
            <Button
              onClick={handleSignup}
              fill="blue"
              style={{
                maxWidth: 200,
                marginRight: "16px",
                marginTop: "16px",
              }}
            >
              <Typography variant="h6">Sign up</Typography>
            </Button>
          </Flex>
        </Copy>
      </Container>
    </div>
  );
};

export default About;
const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
  background-color: black;
`;
const Copy = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1100px;
  margin-left: 64px;
  margin-right: 64px;
  color: white;
  height: 80vh;
  position: relative;
  justify-content: center;
  > h1 {
    font
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 16px;
    margin-right: 16px;
    padding: 16px;
  }
`;

const Flex = styled.div`
  display: flex;
  padding-bottom: 16px;
`;

const BackgroundVideo = styled.video`
  display: inline-block;
  opacity: 0.5;
  position: absolute;
  top: 50%;
  left: 50%;
  bottom: auto;
  right: auto;
  transition: all 2400ms cubic-bezier(0.8, 0, 0.145, 1);
  transition-delay: 1800ms;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  @media (max-width: ${props => props.theme.screen.sm}) {
    top: 50%;
  }
`;

const Accent = styled.span`
  color: #ffd939;
`;
